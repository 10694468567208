import React, { Component, useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import Layout from "../layouts/en";
import SEO from "../components/seo";
import langData from "../data/static-pages/pages/activation-success/en";
import { updateTokenActivation } from "../services/Auth";
import { loadState, saveState, clearAllState } from "state/utils/localStorage";
import $ from "jquery"
import { Spin } from "antd";

const ActivationSuccessPage = (props) => {

    const [showLoading, setLoading] = useState(true)

    useEffect(() => {
        init();
    }, []);

    const init = () => {

        try {

            const parsingData = props.location.search.split('&')

            if (parsingData.length) {

                const token = parsingData[0].split('?token=');
                const source = parsingData[1] ? parsingData[1].split('=') : [];
                const key = parsingData[2] ? parsingData[2].split('=') : [];

                if (token.length > 1) {

                    (async () => {

                        const { data, status_code, message } = await updateTokenActivation(token[1],key[1]);

                        if (source.length > 1) {

                            if (status_code === 200 && source[0] === "source" && source[1] === "website") {
                                saveState("token", token[1]);
                                navigate('/create-new-password');
                            }
                            else if (status_code === 200 && source[0] === "source" && source[1] === "mobile_app") {
                                setLoading(false)
                            }
                            else navigate('/login')
                        }
                        else {

                            if (status_code !== 200) {
                                alert(message);
                                navigate('/login')
                            }
                            else setLoading(false)

                        }

                    })()
                }
                else navigate('/login')
            }
            else navigate('/login')


        } catch (error) {
            console.log(error)
        }
    }

    return (

        <Spin spinning={showLoading}>
            <Layout location={props.location}>

                <SEO title="Activation Success "
                    keyword="Activation Success"
                    decription="Activation Success" />


                <div className="reg container container--800">
                    {showLoading == false && (
                        <>
                            <h1 className="text--center">{langData.title}</h1>

                            <p className="p--sm text--center mart--xxl marb--xxl">
                                {langData.description_mobile}
                            </p>
                        </>
                    )}

                </div>


            </Layout>
        </Spin>
    )

}
export default ActivationSuccessPage